<template>
    <div class="batch-set-rule">
        <!-- <label>批检号生成规则：</label>
        <input v-model="batchRule" placeholder="请输入" @blur="saveBatchRule">
        <label>吨</label> -->
        <div class="batch-set-rule">
            <label>批检号生成规则：</label>
            <el-radio-group class="rule_type" v-model="ruleForm.rule_type">
                <el-radio :label="1">
                    按时间
                </el-radio>
                <el-radio :label="2">
                    按重量
                </el-radio>
            </el-radio-group>
            <el-radio-group v-if="ruleForm.rule_type==1" v-model="ruleForm.time_type">
                <el-radio :label="1" @click.native.prevent="changeTimeType(1)">
                    日检
                </el-radio>
                <el-radio :label="2" @click.native.prevent="changeTimeType(2)">
                    周检
                </el-radio>
            </el-radio-group>
            <template v-else>
                <input type="number" placeholder="请输入" v-model="ruleForm.weight">
                <label>吨</label>
            </template>
        </div>
    </div>
</template>

<script>
import qs from 'qs';
export default {
    name: 'batch-set-rule',
    props: {
        currentData: {
            type: [Object],
        },
    },
    data() {
        return {
            // 批检号生成规则值
            oldBatchRule: '',
            batchRule: '',

            // 批检号生成规则
            ruleForm: {
                id: null,
                // 规则类型 1时间 2重量
                rule_type: 1,
                // 按时间生成时的时间类型 1日检 2周检
                time_type: null,
                // 按重量生成时的吨数
                weight: null,
            },
        };
    },
    watch: {
        currentData: {
            deep: true,
            handler() {
                if (this.currentData && this.currentData.type_dic_code) {
                    this.getBatchRule();
                }
            },
        },
    },
    mounted() {
        if (this.currentData && this.currentData.type_dic_code) {
            this.getBatchRule();
        }
    },
    methods: {
        // 获取披肩规则
        getBatchRule() {
            // this.$axios
            //     .$get('/interfaceApi/procurement/quality/config/batchtesting/get/testingweight')
            //     .then(res => {
            //         this.batchRule = this.oldBatchRule = res;
            //     });
            const params = {
                type_dic_code: this.currentData.type_dic_code,
                child_type_code: this.currentData.child_type_code,
                child_type_dic_code: this.currentData.child_type_dic_code,
                spec_code: this.currentData.spec_code,
                spec_dic_code: this.currentData.spec_dic_code,
            };
            this.$axios
                .get('/interfaceApi/procurement/quality/config/batchtesting/get_generate_rule?' + qs.stringify(params))
                .then(res => {
                    if (res) {
                        this.batchRule = this.oldBatchRule = res;
                    }
                    // else {
                    //     this.ruleForm = this.$options.data().ruleForm;
                    // }
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 设置批检规则
        saveBatchRule() {
            // if (this.batchRule === this.oldBatchRule) {
            //     return;
            // }
            // const weight = this.batchRule || '';
            // this.$axios
            //     .put('/interfaceApi/procurement/quality/config/batchtesting/set/testingweight?weight=' + weight)
            //     .then(res => {
            //         if (res) {
            //             this.oldBatchRule = this.batchRule;
            //             this.$message.success('设置成功');
            //         }
            //     }).catch(error => {
            //         this.$message.error(error.ErrorCode.Message);
            //     });
            let data = {
                type_code: this.currentData.type_code,
                type_dic_code: this.currentData.type_dic_code,
                type_name: this.currentData.type_name,
                child_type_code: this.currentData.child_type_code,
                child_type_dic_code: this.currentData.child_type_dic_code,
                child_type_name: this.currentData.child_type_name,
                spec_code: this.currentData.spec_code,
                spec_dic_code: this.currentData.spec_dic_code,
                spec_name: this.currentData.spec_name,
                rule_type: this.ruleForm.rule_type,
                time_type: this.ruleForm.time_type,
                weight: this.ruleForm.weight,
            };
            if (this.ruleForm.id) {
                data = Object.assign({}, this.ruleForm);
            }
            this.$axios
                .post('/interfaceApi/procurement/quality/config/batchtesting/set_generate_rule', data)
                .then(() => {
                    this.getBatchRule();
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        changeTimeType(val) {
            if (this.ruleForm.time_type === val) {
                this.ruleForm.time_type = null;
            } else {
                this.ruleForm.time_type = val;
            }
            document.querySelector('.el-radio:focus').blur();
        },
    },
};
</script>

<style lang="stylus" scoped>
    .batch-set-rule
        display flex
        align-items center
        font-size 0.16rem
        color #333333
        .rule_type
            margin-left:0.2rem;
            margin-right: 0.5rem;
            border-right: 1px solid #bebebe
            padding-right: 0.2rem;
        input
            width: 1.6rem;
            height: 0.32rem;
            background: #F5F5F5;
            border-radius: 0.04rem;
            border: 1px solid #D0D0D0;
            margin-right 0.04rem
            padding-left 0.1rem
</style>
